<div class="row">
  <div class="col-xl-4 col-md-6">
    <div class="card">
      <!--GESTION AGENTE-->
      <div class="card-header">
        <h3>{{ "encuestas.titulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <!--Lista idiomas-->
              <kendo-label text="{{ 'eventos.idiomas' | translate }}">
              </kendo-label>
              <div class="caja">
                <kendo-dropdownlist
                  #dropdownI
                  [data]="listaIdiomas"
                  textField="nombre"
                  valueField="codigo"
                  [(ngModel)]="selectedIdioma"
                ></kendo-dropdownlist>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <!--Titulo-->
          <kendo-textbox
            placeholder="{{ 'encuestas.ph_titulo' | translate }}"
            [clearButton]="true"
            [(ngModel)]="titulo"
          >
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton look="clear" icon="calendar"></button>
            </ng-template>
          </kendo-textbox>
        </div>

        <!--Cuerpo-->
        <div class="form-group">
          <kendo-label text="{{ 'encuestas.lbl_descripcion' | translate }}">
            <textarea
              kendoTextArea
              style="resize: none"
              rows="5"
              [(ngModel)]="cuerpo"
            >
            </textarea>
          </kendo-label>
        </div>

        <!-- Traducciones -->
        <div class="form-group">
          <button
            type="button"
            class="btn btn-primary btn-sm mr-1"
            (click)="btnAddTraduccion()"
          >
            {{ "eventos.anadir" | translate }}
          </button>
          <div class="listado-traducciones">
            <div class="traduccion" *ngFor="let idioma of listaTraducciones">
              {{ idioma.nombre }}

              <button
                type="button"
                class="btn btn-danger btn-sm mr-1"
                (click)="btnDeleteTraduccion(idioma)"
              >
                X
              </button>
            </div>
          </div>

          <!--Boton Publicar-->
          <button
            type="button"
            [disabled]="isBusy"
            class="btn btn-success btn-sm mr-1"
            (click)="btnCrearEncuesta()"
          >
            {{ "encuestas.crear" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-1"
            (click)="btnCancelar()"
          >
            {{ "botones.cancelar" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "encuestas.titulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <div *ngIf="isPuntuada">
            <kendo-label
              class="k-checkbox-label"
              text="{{ 'encuestas.lbl_saldo' | translate }}"
            ></kendo-label>
            <kendo-numerictextbox
              placeholder="Valor Distribuible"
              [(ngModel)]="saldo"
            >
            </kendo-numerictextbox>
          </div>
        </div>

        <!--Imagen Publicacion-->
        <label class="image-upload-container btn btn-bwm">
          <span>Select Image</span>
          <input
            #imageInput
            type="file"
            accept="image/*"
            (change)="imagePreview($event)"
          />
        </label>

        <div *ngIf="filePath && filePath !== ''">
          <img [src]="filePath" style="height: 200px" />
        </div>

        <!-- Encuesta Fechas-->
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.fechaini' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaInicio">
            </kendo-datepicker>
            <kendo-timepicker
              format="HH:mm:ss"
              [(value)]="horaInicio"
            ></kendo-timepicker>
          </div>
        </div>
        <div class="form-group">
          <kendo-label
            class="k-checkbox-label"
            text="{{ 'eventos.fechafin' | translate }}"
          ></kendo-label>
          <div class="caja">
            <kendo-datepicker format="dd/MMM/yyyy" [(value)]="fechaFin">
            </kendo-datepicker>
            <kendo-timepicker
              format="HH:mm:ss"
              [(value)]="horaFin"
            ></kendo-timepicker>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>{{ "encuestas.titulo" | translate }}</h3>
      </div>
      <div class="card-body">
        <!--Opciones-->
        <div class="form-group anadir-opciones">
          <button
            kendoButton
            (click)="btnAddOption()"
            class="btn btn-success btn-anadir-opcion"
            icon="plus"
          ></button>

          <div class="linea-opcion" *ngFor="let opcion of listaOpciones">
            <div>
              <kendo-textbox
                placeholder="{{ 'eventos.opcion' | translate }}"
                [clearButton]="true"
                [(ngModel)]="opcion.opcion"
              >
              </kendo-textbox>

              <button
                type="button"
                class="btn btn-danger"
                (click)="btnDeleteOption(opcion)"
              >
                X
              </button>

              <kendo-numerictextbox
                *ngIf="isPuntuada"
                placeholder="Valor"
                [(ngModel)]="opcion.puntuacion"
              >
              </kendo-numerictextbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
