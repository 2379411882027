import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Encuesta } from '@app/_models/encuesta';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { EncuestasService } from '@app/_services/encuestas.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {

  listaEncuestas:Encuesta[]=[];


    constructor(private usuariosService: UsuariosService, 
      public encuestasService:EncuestasService,
      private alertService: AlertService,
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    

      }


    
      ngOnInit(): void {
        this.menuService.titulo = this.translateService.instant('menu.inicio');
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('menu.inicio');
        });
        //Consultar encuesetas y mostrar
        this.encuestasService.getEncuestasActivas().subscribe((result)=>{
          console.log('LLEGAN ENCUESTAS');
          console.log(result);
          this.listaEncuestas = result.data;
        });
      }

      navigateVotar(encuesta){
        console.log('EL usuario ha votado: '+encuesta.userVoted);
        if(encuesta.userVoted){
          this.alertService.info(
            this.translateService.instant('encuestas.alreadyvoted')
          );
          return;
        }
        console.log(encuesta.titulo);
        this.router.navigate(['encuestas-votar',{idEncuesta:encuesta.id}]);
      }
}