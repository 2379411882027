import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { UsuariosService, AlertService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({ templateUrl: 'login.component.html' ,
styleUrls: ['./login.scss']})
export class LoginComponent implements OnInit {
  loading = false;
  submitted = false;

  email: string = '';
  password: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    translateService.setDefaultLang(translateService.getBrowserLang());
  }


  ngOnInit() {
    this.translateService.setDefaultLang('eu');
    //this.onLocaleChange(this.user.idioma);

    this.usuariosService.test().subscribe((result) => {
      console.log(result);
    });
  }

  btnLogin() {
    console.log('Trying to login');
    if(this.email==null || this.email.length<1 || this.password==null || this.password.length<1){
      this.alertService.info(
        this.translateService.instant('login.validacion')
      );
      return;
    }
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();

    this.loading = true;
    this.usuariosService
      .login(0, this.email, this.password)
      .pipe(first())
      .subscribe({
        next: () => {
          // get return url from query parameters or default to home page
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.router.navigateByUrl(returnUrl);
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
      });
  }

  btnRegister(){
    console.log('Navigatng Register');
    this.router.navigateByUrl('phone-verification');
  }

}
