import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { UsuariosService, AlertService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { Usuario } from '@app/_models';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
  loading = false;
  submitted = false;

  iddb: number = 0;
  nombreUsuario: string = '';
  nombre: string = '';
  apellido1: string = '';
  apellido2: string = '';
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  tema: Number = 1;
  idioma: string = 'es';
  menuExpandido: boolean = false;
  activo: boolean = false;
  condicionesaceptadas: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    public translateService: TranslateService
  ) {
    translateService.setDefaultLang(translateService.getBrowserLang());
  }

  ngOnInit() {}

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    this.loading = true;
    let myUser:Usuario = new Usuario();
    myUser.idDb=this.iddb;
    myUser.nombreUsuario=this.nombreUsuario;
    myUser.nombre=this.nombre;
    myUser.apellido1=this.apellido1;
    myUser.apellido2=this.apellido2;
    myUser.email=this.email;
    myUser.idDb=this.iddb;

    this.usuariosService
      .register(myUser)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(
            this.translateService.instant('usuarios.registrocorrecto'),
            { keepAfterRouteChange: true }
          );
          this.router.navigate(['../login'], { relativeTo: this.route });
        },
        error: (error) => {
          this.alertService.error(error);
          this.loading = false;
        },
      });
  }
}
