<div
  *ngIf="verificado; else verificar"
  class="d-flex justify-content-center align-items-center container"
>
  <div class="card py-5 px-3">
    <h5 class="m-0">{{ "registro.verificar" | translate }}</h5>
    <span class="mobile-text"
      >{{ "registro.introducecodigo" | translate }} <b class="text-danger"
        >{{phone}}</b
      ></span
    >
    <div class="d-flex flex-row mt-5">
      <kendo-textbox
      type="text"
      name="code"
      [(ngModel)]="verificationCode"
      class="input"
      ></kendo-textbox>
    </div>
    <div class="text-center mt-5">
      <button class="btn btn-success" (click)="verifyLoginCode()">
        {{ "registro.verify" | translate }}
      </button>
    </div>
  </div>
</div>
<ng-template #verificar>
  <div class="card login">
    <div class="card-body">
      <div [hidden]="user">
        <!--<h1>{{ "registro.registro" | translate }}</h1>-->
        <img src="./img/cfg/logo.png"
             id="icon"
             alt="User Icon" />


        <!-- <label>{{ "registro.nombre" | translate }}</label><br />
  <kendo-textbox type="text"
                 [(ngModel)]="nombre"
                 class="input"
                 placeholder=""></kendo-textbox>
  <br> -->
      <label>NAN: (*Letra maiuskularekin) /<br> DNI: (*con letra en mayúsculas)<br></label><br />
        <kendo-textbox type="text"
                       [(ngModel)]="dni"
                       class="input"
                       required
                       [showSuccessIcon]="dni.length == 9"
                       showErrorIcon="initial"
                       placeholder=""></kendo-textbox>
        <br>
        <label>Data / Fecha</label><br />
        <kendo-datepicker required
                          class="w-100"
                          [(ngModel)]="fechaNacimiento"
                          placeholder="Jaiotze data / Fecha de nacimiento "
                          format="yyyy MMMM dd">
                          <kendo-datepicker-messages
                          today="Gaur"
                          toggle="Egutegia aldatu"
                        ></kendo-datepicker-messages>
                        </kendo-datepicker>
        <br>
        <label for="phone">Mugikor zenbakia / Número de móvil</label><br />
        <kendo-textbox type="text"
                       [(ngModel)]="phone"
                       class="input"></kendo-textbox>
        <br>
        <!-- <label>{{ "registro.email" | translate }}</label><br />
  <kendo-textbox type="email"
                 [(ngModel)]="email"
                 class="input"
                 required
                 showErrorIcon="initial"
                 placeholder=""></kendo-textbox>
  <br>
  <label>{{ "registro.pass1" | translate }}</label><br />
  <input kendoTextBox type="password"
                 [(ngModel)]="password1"
                 required
                 [showSuccessIcon]="password1.length > 4"
                 showErrorIcon="initial"
                 />

  <br>
  <label>{{ "registro.pass2" | translate }}</label><br />
  <input kendoTextBox type="password"
                 [(ngModel)]="password2"
                 required
                 [showSuccessIcon]="password2==password1 && password2.length > 4"
                 showErrorIcon="initial"
                 /> -->


        <div id="recaptcha-container"></div>

        <!-- <button class="btn btn-success" (click)="sendLoginCode()">
    {{ "registro.registrar" | translate }}
  </button> -->
      <button kendoButton
              [disabled]="isBusy"
              class="btn btn-success w-100 mt-3" (click)="sendLoginCode()">
        <span *ngIf="isBusy"
              class="spinner-border spinner-border-sm mr-1"></span>
        Sartu / Acceder
      </button>

        <!-- <button class="btn btn-danger" (click)="btnCancelar()">
    {{ "registro.cancelar" | translate }}
  </button> -->

        <div *ngIf="windowRef.confirmationResult">



          <label for="code">{{ "registro.introducecodigo" | translate }}</label><br />
          <kendo-textbox type="text"
                         name="code"
                         [(ngModel)]="verificationCode"
                         class="input"></kendo-textbox>

          <button class="btn btn-success w-100" (click)="verifyLoginCode()">
            {{ "registro.verify" | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="user">
        <div class="notification is-success">
          You have successfully logged in with your phone number!
        </div>

        UserId: {{ user?.uid }}
      </div>
    </div>
    </div>
</ng-template>

<div class="login-bg" style="background-image: url(./img/cfg/bg.jpg);"></div>
<!--<style>
  .card {
    width: 350px;
    padding: 10px;
    border-radius: 20px;
    background: #fff;
    border: none;
    height: 350px;
    position: relative;
  }

  .container {
    height: 100vh;
  }

  body {
    background: #eee;
  }

  .mobile-text {
    color: #989696b8;
    font-size: 15px;
  }

  .form-control {
    margin-right: 12px;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ff8880;
    outline: 0;
    box-shadow: none;
  }

  .cursor {
    cursor: pointer;
  }
</style>-->
