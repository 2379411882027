<ul
  class="list-group list-group-flush"
>

<kendo-grid
[kendoGridBinding]="listaPublicaciones"
[sortable]="true"
kendoGridSelectBy="id"
[navigable]="true"
[filterable]="true"
(cellClick)="cellClick()"
[selectedKeys]="seleccionados"
[pageSize]="15"
[pageable]="true"
>
<ng-template kendoGridToolbarTemplate position="top">
  <button
    kendoButton
    (click)="onClickEditar()"
    class="btn mr-1 btn-success btn-sm mr-1"
  >
    {{ "botones.editar" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickNuevo()"
    class="btn mr-1 btn-primary btn-sm mr-1"
  >
    {{ "botones.nuevo" | translate }}
  </button>
  <button
    kendoButton
    (click)="onClickEliminar(content)"
    class="btn mr-1 btn-danger btn-sm mr-1"
    [disabled]="isDeleting"
  >
    {{ "botones.eliminar" | translate }}
  </button>

</ng-template>
<kendo-grid-checkbox-column
  width="1%"
  showSelectAll="true"
></kendo-grid-checkbox-column>
<kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="titulo"
  title="{{ 'publicaciones.titulo_publi' | translate }}"
>
</kendo-grid-column>

<kendo-grid-column
  field="fecha"
  title="{{ 'publicaciones.grid_lbl_fecha' | translate }}"
  width="15%"
>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <kendo-grid-date-filter-cell
      [showOperators]="false"
      [column]="column"
      [filter]="filter"
    >
    </kendo-grid-date-filter-cell>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    {{ dataItem.fecha | date: "dd/MM/yyyy" }}
  </ng-template>
</kendo-grid-column>

<!-- <kendo-grid-column
  width="20%"
  [style]="{ 'text-align': 'left' }"
  field="activa"
  title="{{ 'organizaciones.activo' | translate }}"
>
  <ng-template kendoGridCellTemplate let-dataItem>
    <input
      type="checkbox"
      [checked]="dataItem.organizacionActiva"
      disabled
    />
  </ng-template>
</kendo-grid-column> -->
</kendo-grid>
</ul>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "organizaciones.eliminar" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      {{ "botones.no" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="eliminarRegistro(contentloading)"
    >
      {{ "botones.si" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ "organizaciones.eliminando" | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ "organizaciones.eliminando" | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>


