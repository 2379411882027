import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  DataService,
  DropDownListComponent,
} from '@progress/kendo-angular-dropdowns';
import { AlertService } from '@app/_services';
import {
  Encuesta,
  EncuestaOpcion,
  EncuestaOpcionTraduccion,
  EncuestaTipo,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { EncuestasService } from '@app/_services/encuestas.service';
import { NavigationEnd } from '@angular/router';
import { Usuario, Votante } from '@app/_models';
import { filter } from 'rxjs/operators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from "@progress/kendo-data-query";

@Component({
  selector: 'app-comprobar-dni',
  templateUrl: './comprobar-dni.component.html',
})
export class ComprobarDniComponent implements OnInit {
  listaUsers: Votante[] = [];
  userDNI: string = '';
  anadirDNI: string = '';
  lang;
  private translate: TranslateService;

  constructor(
    private encuestaService: EncuestasService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private usuarioService:UsuariosService,
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('encuestas.verificar');
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    //load users
    this.encuestaService
      .getVotantesGlobalesEncuesta(9)//Villabona quiere solo para esta encuesta
      .subscribe((resultUsers) => {
        this.listaUsers = resultUsers.data;
      });
  }

  btnBuscarUsuario() {
    if (this.userDNI.length < 9) {
      this.alertService.warn(
        this.translateService.instant('encuestas.dni_invalido')
      );
      return;
    }
    let listaTmp: Votante[] = this.listaUsers.filter(
      (x) => x.dni == this.userDNI
    );
    if (listaTmp.length > 0) {
      this.alertService.warn(
        this.translateService.instant('encuestas.usuariohavotado')
      );
    } else {
      this.alertService.success(
        this.translateService.instant('encuestas.usuarionohavotado')
      );
    }
  }

  btnAddVotacion() {
    if (this.anadirDNI.length < 9) {
      this.alertService.warn(
        this.translateService.instant('encuestas.dni_invalido')
      );
      return;
    }
    console.log('Registrando Usuario...');
   
    this.encuestaService
      .registerDNIVotanteFisico(this.anadirDNI,9)
      .subscribe((res)=>{
        console.log(res);
        if(!res.error){
          this.alertService.success(
            this.translateService.instant('encuestas.dnianadido')
          );
          this.encuestaService
          .getVotantesGlobalesEncuesta(9)//Villabona quiere solo para esta encuesta
          .subscribe((resultUsers) => {
            this.listaUsers = resultUsers.data;
          });
        }
        else if(res.error && res.codigo==2){
          this.alertService.error(
            this.translateService.instant('encuestas.alreadyinlist')
          );
        }
        else{
          console.log('error al insertar en el registro');
        }
      });
      
  }
  
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.listaUsers, {
        sort: [{ field: "dni", dir: "asc" }],
      }).data,

    };

    return result;
  }
}
