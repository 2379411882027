import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';

const baseUrl = `${environment.apiUrl}/usuarios`;

@Injectable({ providedIn: 'root' })
export class UsuariosService {
  private userSubject: BehaviorSubject<Usuario>;
  public user: Observable<Usuario>;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<Usuario>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): Usuario {
    return this.userSubject.value;
  }

  test() {
    return this.http.get(`${baseUrl}/test`);
  }

  login(idDb: number, email: string, password: string) {
    console.log('Datos');
    console.log(email);
    console.log(password);
    return this.http
      .post<any>(
        `${baseUrl}/authenticate`,
        { idDb, email, password },
        { withCredentials: true }
      )
      .pipe(
        map((user) => {
          console.log('USUARIO OBTENIDO AUTHENTICATE');
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  logout() {
    this.http
      .post<any>(`${baseUrl}/revoke-token`, {}, { withCredentials: true })
      .subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['login/login']);
  }

  refreshToken() {
    return this.http
      .post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(
        map((user) => {
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  register(user: Usuario) {
    return this.http.post(`${baseUrl}/register`, user);
  }

  verifyEmail(token: string) {
    return this.http.post(`${baseUrl}/verify-email`, { token });
  }

  forgotPassword(idDb: number, email: string) {
    return this.http.post(`${baseUrl}/forgot-password`, { idDb, email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-reset-token`, { token });
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${baseUrl}/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  getAll() {
    return this.http.get<Usuario[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<Usuario>(`${baseUrl}/${id}`);
  }

  create(params) {
    return this.http.post(baseUrl, params);
  }

  update(params) {
    return this.http.post(baseUrl + '/update', params).pipe(
      map((user: any) => {
        // update the current user if it was updated
        if (user.id === this.userValue.id) {
          // publish updated user to subscribers
          user = { ...this.userValue, ...user };
          this.userSubject.next(user);
        }
        return user;
      })
    );
  }

  delete(id: number, params) {
    return this.http.post(baseUrl + '/delete', params).pipe(
      finalize(() => {
        // auto logout if the logged in user was deleted
        if (id === this.userValue.id) this.logout();
      })
    );
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  //VILLABONA CENSO

  APICenso="https://desa1.sare.gipuzkoa.net/WAS/AYTO/URJUdalRecursosWSExtWEB/estaEmpadronado";
  
  public consultarCiudadano(): Observable<any> {

    return this.http.post(
      this.APICenso ,
      {
        idioma:"B",
        dni:"72558254Q",
        dniPeticionario:"12345678UZ",
        fechanac:"19951228",
        nombrePeticionario:"Nombre apellido",
        codprocedimiento:"UDWEBS02"
      },
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
          USERNAME:"USUWBS17",
          PASSWORD:"DESA",
          IP:"83.213.115.108"
        }),
        withCredentials: true,
      }
    );
  }
}
