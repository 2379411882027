import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({ templateUrl: 'layout.component.html' })
export class LayoutComponent {
    constructor(
        private router: Router,
        private usuariosService: UsuariosService,
    ) {
        // redirect to home if already logged in
        if (this.usuariosService.userValue) {
            this.router.navigate(['/']);
        }
    }

}