import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';

import { UsuariosService, AlertService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({ templateUrl: 'forgot-password.component.html' })
export class ForgotPasswordComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private usuariosService: UsuariosService,
        private alertService: AlertService,
        private translateService: TranslateService
    ) {
        translateService.setDefaultLang(translateService.getBrowserLang());
     }

    ngOnInit() {
        this.form = this.formBuilder.group({
            idDB: 0,
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.alertService.clear();
        this.usuariosService.forgotPassword(this.f.idDB.value, this.f.email.value)
            .pipe(first())
            .pipe(finalize(() => this.loading = false, ))
            .subscribe({
                next: () =>  this.alertService.success(this.translateService.instant('usuarios.consultaremail')),
                error: error => this.alertService.error(error)
            });
    }
}