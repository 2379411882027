<h3 class="card-header">{{ 'usuarios.contraseñaolvidada' | translate}}</h3>
<div class="card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- <div class="form-group">
            <label>ID</label>
            <input type="number" formControlName="idDb" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.idDb.errors }" />
            <div *ngIf="submitted && f.idDb.errors" class="invalid-feedback">
                <div *ngIf="f.idDb.errors.required">ID is required</div>
            </div> 
        </div> -->
        <div class="form-group">
            <kendo-label text="{{ 'usuarios.email' | translate}}">
                <kendo-textbox formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"></kendo-textbox>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">{{ 'usuarios.emailrequerido' | translate}}</div>
                    <div *ngIf="f.email.errors.email">{{ 'usuarios.emailincorrecto' | translate}}</div>
                </div>
            </kendo-label>
            
        </div>
        <div class="form-group">
            <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'botones.enviar' | translate}}
            </button>
            <a routerLink="../login" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
        </div>
    </form>
</div>
