<div *ngIf="!finalizada;else bloquefin" class="card card-votacion">

  <div class="card-header">
    <h3>{{myEncuesta.titulo}}</h3>
    <div class="plegarpanel"></div>
  </div>

  <div class="card-body">
    <div class="imagen-votacion" *ngIf="myEncuesta.urlFoto.length>0">
      <img src="{{myEncuesta.urlFoto}}" />
    </div>
    {{myEncuesta.descripcion}}
    <div class="villabona-testua">
      <p class="adi">{{ "encuestas.leyenda_atencion" | translate }}</p>

      <p>{{ "encuestas.leyenda_puntua" | translate }}</p>
      <ul>
        <li><span>{{ "encuestas.leyenda_selecciona" | translate }}</span><span class="testuko-puntuak">3</span> <span>{{ "encuestas.leyenda_elquemas" | translate }}</span></li>
        <li><span>{{ "encuestas.leyenda_selecciona" | translate }}</span><span class="testuko-puntuak">2</span> <span>{{ "encuestas.leyenda_segundo" | translate }}</span></li>
        <li><span>{{ "encuestas.leyenda_selecciona" | translate }}</span><span class="testuko-puntuak">1</span> <span>{{ "encuestas.leyenda_tercero" | translate }}</span></li>
      </ul>

      <p>{{ "encuestas.leyenda_almenos" | translate }}</p>

    </div>




    <!-- <button (click)="reloadCurrentRoute()">Reload</button> -->
    <div class="opciones-puntos">
      <div class="opcion-puntos-cont clearfix" *ngFor="let opcion of myEncuesta.listaOpciones">
        <div class="opcion-titulo">



          <!-- <div (click)="btnSeleccionado(opcion)"  [ngClass]="{ seleccionado: opcion.Seleccionada,normal: !opcion.Seleccionada }" >
            {{opcion.puntuacion}}  {{opcion.opcion}}
        </div> -->
          {{ opcion.opcion }}
        </div>
        <div class="botones-puntos">

          <button kendoButton (click)="addOption(opcion,3)" [ngClass]="{ seleccionado: opcion.Seleccionada3,normal: !opcion.Seleccionada3 }">3</button>
          <button kendoButton (click)="addOption(opcion,2)" [ngClass]="{ seleccionado: opcion.Seleccionada2,normal: !opcion.Seleccionada2 }">2</button>
          <button kendoButton (click)="addOption(opcion,1)" [ngClass]="{ seleccionado: opcion.Seleccionada1,normal: !opcion.Seleccionada1 }">1</button>

          <!-- <kendo-buttongroup selection="single">

              <button kendoButton
                      class="botongrupo"
                      (click)="btnAdd(opcion, 1)"
                      [toggleable]="true"
                      [disabled]="opcion.disabled"
                      [ngClass]="{
          seleccionado: opcion.Seleccionada,
          normal: !opcion.Seleccionada
        }">
                1
              </button>
              <button kendoButton
                      class="botongrupo"
                      (click)="btnAdd(opcion, 2)"
                      [toggleable]="true"
                      [disabled]="opcion.disabled"
                      [ngClass]="{
          seleccionado: opcion.Seleccionada,
          normal: !opcion.Seleccionada
        }">
                2
              </button>
              <button kendoButton
                      class="botongrupo"
                      (click)="btnAdd(opcion, 3)"
                      [toggleable]="true"
                      [disabled]="opcion.disabled"
                      [ngClass]="{
          seleccionado: opcion.Seleccionada,
          normal: !opcion.Seleccionada
        }">
                3
              </button>
            </kendo-buttongroup> -->
        </div>
      </div>
    </div>


    <!--<div *ngIf="listaPuntos.length>0; else finalizado">
    Selecciona la opcion a la que asignas {{listaPuntos[0]}} puntos;
  </div>

  <ng-template #finalizado>Has repartido todos los puntos, envia tu votación!</ng-template>

  <div class="opciones">

    <div class="opcion-cont clearfix" *ngFor="let opcion of myEncuesta.listaOpciones">
      <div class="opcion-cont-inner" (click)="btnSeleccionado(opcion)" [ngClass]="{ seleccionado: opcion.Seleccionada,normal: !opcion.Seleccionada }">
        <div class="puntos">{{opcion.puntuacion}} </div><div class="opcion-nombre">{{opcion.opcion}}</div>
      </div>
    </div>

  </div>-->


    <div class="text-center clearfix">
      <button type="button"
              class="btn btn-success btn-lg mb-3"
              (click)="btnEnviarVotacion()">
        {{ "encuestas.votar" | translate }}
      </button>
    </div>

    <!--<div class="text-center clearfix">
      <button type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()">
        {{ "botones.cancelar" | translate }}
      </button>

    </div>-->

    </div>

  </div>

  <ng-template #bloquefin>
    <h1>{{ "encuestas.finalizada" | translate }}</h1>
  </ng-template>

  <!--<style>
    .seleccionado{
      background-color: greenyellow;
    }
    .normal{
      background-color: honeydew;
    }
  </style>-->
