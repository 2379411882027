<div class="row">
  <div *ngFor="let encuesta of listaEncuestas" class="bloque-encuesta m-2">
    <!--col-xl-2 col-lg-2 col-md-4 col-sm-4-->

    <div class="card">
      <div class="card-body">
        <div (click)="navigateVotar(encuesta)">
          <div
            class="clearfix"
            *ngIf="encuesta.urlFoto.length > 1; else sinfoto"
          >
            <img src="{{ encuesta.urlFoto }}" />
          </div>
          <ng-template #sinfoto>
            <img src="./img/cfg/encuesta-icono.png"/>
          </ng-template>

          <div class="clearfix">
            <div class="titulo-encuesta">
              {{ encuesta.titulo }}
            </div>
          </div>

          <div class="clearfix">
            <div class="fecha-encuesta">{{ encuesta.fecha_fin }}</div>
            <div class="votado" *ngIf="encuesta.userVoted">{{ "encuestas.voted" | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
