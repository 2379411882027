
<div class="card">
  <div class="card-header">
    <h3>{{ myEncuesta.titulo }}</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">

        <kendo-chart>
          <kendo-chart-series>
            <kendo-chart-series-item autoFit="true"
                                     type="donut"
                                     [data]="listaOpciones"
                                     categoryField="opcion"
                                     field="puntuacion">
              <kendo-chart-series-item-labels position="outsideEnd"
                                              color="#000"
                                              [content]="labelContent">
              </kendo-chart-series-item-labels>
            </kendo-chart-series-item>
          </kendo-chart-series>
          <ng-template kendoChartDonutCenterTemplate>
            <h3>{{ 'encuestas.total' | translate }}</h3>
            {{ votosTotales }}
          </ng-template>
          <kendo-chart-legend [visible]="true" position="bottom" ></kendo-chart-legend>
        </kendo-chart>

      </div>

      <div class="col-md-12">
        
          <table class="tabla-datos-encuesta" >
            <tr>
              <th class="titulo-tabla-opcion">{{ 'encuestas.opcion' | translate }}</th>
              <th class="titulo-tabla-3puntos">{{ 'encuestas.3puntos' | translate }}</th>
              <th class="titulo-tabla-puntos">{{ 'encuestas.puntos' | translate }}</th>
              <th class="titulo-tabla-porcentaje">{{ 'encuestas.porcentaje' | translate }}</th>
            </tr>

            <tr *ngFor="let opcion of listaOpciones">
              <td class="celda-opcion"> {{opcion.opcion}}</td>
              <td class="celda-3puntos"> {{opcion.numVotos3}}</td>
              <td class="celda-puntos"><!--Puntos Obtenidos:-->{{opcion.puntuacion}}</td>
              <td class="celda-porcentaje"><!--Porcentaje puntuación obtenida:--> {{(opcion.puntuacion/puntosTotales) | percent:'0.1-3'}}</td>
            </tr>

          </table>
          <br>
          <div *ngIf="!empate;else empatebloque">
            <h3> {{ 'encuestas.ganadora' | translate }}: <h4>{{ganador}}</h4></h3>
          </div>
          <ng-template #empatebloque>
           <h3>{{ 'encuestas.empate' | translate }}</h3> 
          </ng-template>

      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="clearfix">

      <div class="float-left">
        <kendo-textbox class="float-left mr-2"
                       style="width: 320px;"
                       placeholder="{{ 'encuestas.ph_dni' | translate }}"
                       [clearButton]="true"
                       [(ngModel)]="userDNI">
          <ng-template kendoTextBoxPrefixTemplate>
            <button kendoButton icon="search"></button>
          </ng-template>
        </kendo-textbox>
      </div>
      <div class="float-left">
        <button type="button" (click)="btnBuscarUsuario()" class="btn btn-primary">{{'encuestas.buscardni' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<kendo-grid #grid
[kendoGridBinding]="listaUsers"
kendoGridSelectBy="id"
[pageSize]="30"
[pageable]="true"
[navigable]="true"
[sortable]="true"
[filterable]="true"
[resizable]="true"
            [selectable]="{
  cell: false,
  checkboxOnly: false,
  drag: true,
  enabled: true,
  mode: 'multiple'
}">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridPDFCommand icon="file-pdf" class="btn-danger">{{ "encuestas.export" | translate }}</button>
    <!-- <button kendoButton type="button" (click)="imprimirXls($event, grid)" icon="file-excel">Excel</button> -->
    <button type="button" kendoGridExcelCommand icon="file-excel" class="btn-success">Excel</button>
  </ng-template>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="1%">
    <ng-template kendoGridHeaderTemplate>
      <input class="k-checkbox"
             id="selectAllCheckboxId"
             kendoGridSelectAllCheckbox
             [state]="selectAllState"
             (selectAllChange)="onSelectAllChange($event)" />
      <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
    </ng-template>
  </kendo-grid-checkbox-column>

  <kendo-grid-column field="nombreUsuario"
                     title="{{ 'encuestas.dni' | translate }}"
                     width="10%"></kendo-grid-column>
  <kendo-grid-column field="descripcion"
                     title="{{ 'encuestas.phone' | translate }}"
                     width="10%"></kendo-grid-column>
  <!-- <kendo-grid-column field="email"
                     title="{{ 'encuestas.email' | translate }}"
                     width="10%"></kendo-grid-column> -->
  <kendo-grid-excel fileName="Usuarios.xlsx" [fetchData]="allData"></kendo-grid-excel>
  <kendo-grid-pdf fileName="Usuarios.pdf"
                  [allPages]="true"
                  paperSize="A4"
                  [repeatHeaders]="true"
                  [landscape]="true">
    <kendo-grid-pdf-margin top="2cm"
                           left="1cm"
                           right="1cm"
                           bottom="2cm"></kendo-grid-pdf-margin>
    <ng-template kendoGridPDFTemplate
                 let-pageNum="pageNum"
                 let-totalPages="totalPages">
      <div class="page-template">
        <div class="header">
          <div style="float: right">
            Page {{ pageNum }} of {{ totalPages }}
          </div>
        </div>
        <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>
  </kendo-grid-pdf>
</kendo-grid>

