import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  Encuesta,
  EncuestaModel,
  EncuestaOpcion,
  EncuestaOpcionTraduccion,
  EncuestaTipo,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { AlertService } from '@app/_services';
import { EncuestasService } from '@app/_services/encuestas.service';
import { AplicacionesService } from '@app/_services/aplicaciones.service';
import { xCircleIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-encuesta-votar',
  templateUrl: './encuesta-votar.component.html',
})
export class EncuestaVotarComponent implements OnInit {
  titulo: string;
  descripcion: string;
  listaPuntos: number[] = [3, 2, 1];

  idEncuesta: number;
  myEncuesta: Encuesta;
  maximo: number = 0;
  disabled: boolean = false;
  finalizada:boolean=false;

  lang;
  private translate: TranslateService;

  constructor(
    private encuestaService: EncuestasService,
    private usuarioService: UsuariosService,
    private idiomaService: IdiomasService,
    private appService: AplicacionesService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('encuestas.cabecera');
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('encuestas.cabecera');
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // TODO This as a workaround.
      this.menuService.titulo = this.translateService.instant('encuestas.cabecera');
    });
    
    this.idEncuesta = this.route.snapshot.params['idEncuesta'];
    this.encuestaService.getEmcuesta(this.idEncuesta).subscribe((result) => {
      console.log(result);
      this.myEncuesta = result.data[0];
      this.encuestaService
        .getOpcionesFromEncuesta(this.idEncuesta)
        .subscribe((resultOp) => {
          console.log(resultOp);
          this.myEncuesta.listaOpciones = resultOp.data;
          this.myEncuesta.listaOpciones.map((x) => (x.puntuacion = 0));
        });
        const fechaTMP =new Date();
        const fechaEncuesta =new Date(this.myEncuesta.fecha_fin);
        console.log(fechaTMP);
        console.log(fechaEncuesta);
        console.log(fechaEncuesta<=fechaTMP);
    if(fechaEncuesta<=fechaTMP){
      console.log('FINALIZADA');
      this.finalizada=true;
    }
      });

    // this.usuarioService.consultarCiudadano().subscribe((resultAPI)=>{
    //   console.log(resultAPI);
    // });
  }

  btnEnviarVotacion() {

    let asignado = false;
    this.myEncuesta.listaOpciones.forEach((element) => {
      if (element.puntuacion == 3) asignado = true;
    });
    if (!asignado) {
      this.alertService.info(
        this.translateService.instant('encuestas.puntuatodo')
      );
      return;
    }

    this.myEncuesta.listaOpciones = this.myEncuesta.listaOpciones.filter((x)=>x.Seleccionada1||x.Seleccionada2||x.Seleccionada3);
    this.myEncuesta.listaOpciones.forEach((x)=>x.Seleccionada=true);
    
    console.log('Enviando votación');
    this.encuestaService
      .enviarVotacion(this.myEncuesta.listaOpciones, this.idEncuesta)
      .subscribe((resultVotacion) => {
        console.log(resultVotacion);
        if (!resultVotacion.error) {
          this.alertService.success(
            this.translateService.instant('encuestas.successvotar'),{autoclose:false}
          );
          //this.router.navigate(['encuestas']);
          return;
        } else {
          if (resultVotacion.votado) {
            this.alertService.error(
              this.translateService.instant('encuestas.yahasvotado')
            );
            return;
          } else {
            this.alertService.error(
              this.translateService.instant('encuestas.errorvotar')
            );
            return;
          }
        }
      });
  }


  // btnSeleccionado(opcion: EncuestaOpcion) {
  //   if (this.listaPuntos.length <= 0 && !opcion.Seleccionada) {
  //     this.alertService.info(
  //       this.translateService.instant('encuestas.puntosrepartidos')
  //     );
  //     return;
  //   }
  //   // console.log(opcion.opcion);
  //   // console.log(opcion.Seleccionada);
  //   if (!opcion.Seleccionada) {
  //     opcion.Seleccionada = !opcion.Seleccionada;
  //     opcion.puntuacion = this.listaPuntos.shift();
  //   } else {
  //     opcion.Seleccionada = !opcion.Seleccionada;
  //     this.listaPuntos.push(opcion.puntuacion);
  //     this.listaPuntos = this.listaPuntos.sort((x, y) => x - y).reverse();
  //     opcion.puntuacion = 0;
  //   }
  // }

  btnAdd(opcion: EncuestaOpcion, valor: number) {
    console.log(this.maximo);
    this.disabled = this.maximo >= 3;
    if (this.disabled) {
      this.myEncuesta.listaOpciones.forEach((op) => {
        if (!op.Seleccionada) op.disabled = true;
      });
      return;
    }
    if (this.maximo >= 3 && !opcion.Seleccionada) {
      this.alertService.info(
        this.translateService.instant('encuestas.puntosrepartidos')
      );
      return;
    }
    console.log(opcion.opcion + ' : ' + valor);
    if (!opcion.Seleccionada) {
      opcion.puntuacion = valor;
      this.maximo++;
      opcion.Seleccionada = true;
    } else {
      opcion.puntuacion = 0;
      this.maximo--;
      opcion.Seleccionada = false;
    }
  }

  reloadCurrentRoute() {
    window.location.reload();
  }

  btnCancelar() {
    this.router.navigate(['encuestas']);
  }


  addOption(opcion:EncuestaOpcion,valor){
    console.log('LLEGA:');
    console.log(opcion);
    console.log(valor);
    //check valor seleccionado
    let x:EncuestaOpcion;
    if(valor==1 && !opcion.Seleccionada1){
       x = this.myEncuesta.listaOpciones.find((x)=>x.Seleccionada1)
    }
    if(valor==2 && !opcion.Seleccionada2){
       x = this.myEncuesta.listaOpciones.find((x)=>x.Seleccionada2)
    }
    if(valor==3 && !opcion.Seleccionada3){
       x = this.myEncuesta.listaOpciones.find((x)=>x.Seleccionada3)
    }
    if(x!=null){
      this.alertService.info(
        this.translateService.instant('encuestas.yaseleccionada')
      );
      return;
    }

    //Ninguna Seleccionada
    if(!opcion.Seleccionada1 && !opcion.Seleccionada2 && !opcion.Seleccionada3){
      if(valor==1)
      opcion.Seleccionada1=true;
      else if(valor==2)
      opcion.Seleccionada2=true;
      else if (valor==3)
      opcion.Seleccionada3=true;
      opcion.puntuacion = valor;
      return;
    }
    //1 Seleccionada
   if(opcion.Seleccionada1){
      if(valor==1){
        opcion.Seleccionada1=false;
        opcion.puntuacion=0;
      }
      else{
        opcion.Seleccionada1=false;
        opcion.puntuacion=0;
        if(valor==1)
        opcion.Seleccionada1=true;
        else if(valor==2)
        opcion.Seleccionada2=true;
        else if (valor==3)
        opcion.Seleccionada3=true;
        opcion.puntuacion = valor;
      }
    }//2 Seleccionada
    else if(opcion.Seleccionada2){
      if(valor==2){
        opcion.Seleccionada2=false;
        opcion.puntuacion=0;
      }
      else{
        opcion.Seleccionada2=false;
        opcion.puntuacion=0;
        if(valor==1)
        opcion.Seleccionada1=true;
        else if(valor==2)
        opcion.Seleccionada2=true;
        else if (valor==3)
        opcion.Seleccionada3=true;
        opcion.puntuacion = valor;
      }
    }//3 Seleccionada
    else if(opcion.Seleccionada3){
      if(valor==3){
        opcion.Seleccionada3=false;
        opcion.puntuacion=0;
      }
      else{
        opcion.Seleccionada3=false;
        opcion.puntuacion=0;
        if(valor==1)
        opcion.Seleccionada1=true;
        else if(valor==2)
        opcion.Seleccionada2=true;
        else if (valor==3)
        opcion.Seleccionada3=true;
        opcion.puntuacion = valor;
      }
    }
  }
}
