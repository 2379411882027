<h3 class="card-header">{{ 'usuarios.restablecercontraseña' | translate}}</h3>
<div class="card-body">
    <div *ngIf="tokenStatus == TokenStatus.Validating">
        {{ 'usuarios.validandotoken' | translate}}
    </div>
    <div *ngIf="tokenStatus == TokenStatus.Invalid">
        {{ 'usuarios.validartokenfallo' | translate}} <a routerLink="../forgot-password">{{ 'usuarios.contraseñaolvidada' | translate}}</a> {{ 'usuarios.paginareset' | translate}}.
    </div>
    <form *ngIf="tokenStatus == TokenStatus.Valid" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <kendo-label text="{{ 'usuarios.contraseña' | translate}}">
                <input kendoTextBox type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{ 'usuarios.contraseñarequerida' | translate}}</div>
                    <div *ngIf="f.password.errors.minlength">{{ 'usuarios.contraseña6caracteres' | translate}}</div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group">
            <kendo-label text="{{ 'usuarios.confirmarcontraseña' | translate}}">
                <input kendoTextBox type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">{{ 'usuarios.contraseñaconfirmacionrequerida' | translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'usuarios.contraseñasnocoinciden' | translate}}</div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group">
            <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{ 'usuarios.restablecercontraseña' | translate}}
            </button>
            <a routerLink="../login" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
        </div>
    </form>
</div>
