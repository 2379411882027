<div class="card">
    <div class="card-body">
      <div class="clearfix">
        <div class="float-left">
          <kendo-textbox class="float-left mr-2"
                         style="width: 320px;"
                         placeholder="{{ 'encuestas.ph_dni' | translate }}"
                         [clearButton]="true"
                         [(ngModel)]="userDNI">
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton icon="search"></button>
            </ng-template>
          </kendo-textbox> 
        </div>
        <div class="float-left">
          <button type="button" (click)="btnBuscarUsuario()" class="btn btn-primary">{{'encuestas.buscardni' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="clearfix">
        <div class="float-left">
          <kendo-textbox class="float-left mr-2"
                         style="width: 320px;"
                         placeholder="DNI/NAN"
                         [clearButton]="true"
                         [(ngModel)]="anadirDNI">
            <ng-template kendoTextBoxPrefixTemplate>
              <button kendoButton icon="add"></button>
            </ng-template>
          </kendo-textbox> 
        </div>
        <div class="float-left">
          <button type="button" (click)="btnAddVotacion()" class="btn btn-primary">{{'encuestas.anadir' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <!--GRID-->
  <kendo-grid #grid
  [kendoGridBinding]="listaUsers"
  kendoGridSelectBy="id"
  [pageSize]="15"
  [pageable]="true"
  [navigable]="true"
  [sortable]="true"
  [filterable]="true"
  [resizable]="true"
  [selectable]="{
cell: false,
checkboxOnly: false,
drag: true,
enabled: true,
mode: 'multiple'
}">
<ng-template kendoGridToolbarTemplate>
<button kendoGridPDFCommand icon="file-pdf" class="btn-danger">{{ "encuestas.export" | translate }}</button>
<!-- <button kendoButton type="button" (click)="imprimirXls($event, grid)" icon="file-excel">Excel</button> -->
<button type="button" kendoGridExcelCommand icon="file-excel" class="btn-success">Excel</button>
</ng-template>
<!--checkbox-->
<kendo-grid-checkbox-column width="1%">
<ng-template kendoGridHeaderTemplate>
<input class="k-checkbox"
   id="selectAllCheckboxId"
   kendoGridSelectAllCheckbox
   [state]="selectAllState"
   (selectAllChange)="onSelectAllChange($event)" />
<label class="k-checkbox-label" for="selectAllCheckboxId"></label>
</ng-template>
</kendo-grid-checkbox-column>

<kendo-grid-column field="dni"
           title="{{ 'encuestas.dni' | translate }}"
           width="10%"></kendo-grid-column>


           <!-- <kendo-grid-column
           field="created"
           title="First Ordered On"
           width="240"
           filter="date"
           format="{0:d}"
         >
         </kendo-grid-column> -->

           <!-- <kendo-grid-column
           field="created"
           title="{{ 'registro.fecha' | translate }}"
           width="15%"
         >
           <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
             <kendo-grid-date-filter-cell
               [showOperators]="false"
               [column]="column"
               [filter]="filter"
             >
             </kendo-grid-date-filter-cell>
           </ng-template>
           <ng-template kendoGridCellTemplate let-dataItem>
             {{ dataItem.created  }}
           </ng-template>
         </kendo-grid-column> -->

         <kendo-grid-excel fileName="Usuarios.xlsx" [fetchData]="allData"></kendo-grid-excel>
<kendo-grid-pdf fileName="Usuarios.pdf"
        [allPages]="true"
        paperSize="A4"
        [repeatHeaders]="true"
        [landscape]="true">
<kendo-grid-pdf-margin top="2cm"
                 left="1cm"
                 right="1cm"
                 bottom="2cm"></kendo-grid-pdf-margin>
<ng-template kendoGridPDFTemplate
       let-pageNum="pageNum"
       let-totalPages="totalPages">
<div class="page-template">
<div class="header">
<div style="float: right">
  Page {{ pageNum }} of {{ totalPages }}
</div>
</div>
<div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
</div>
</ng-template>
</kendo-grid-pdf>
</kendo-grid>
