import { Component, Injectable, LOCALE_ID, Inject } from '@angular/core';
import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { MyFunctions } from './_helpers';



@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user = this.usuariosService.userValue;
    titulo = this.menuService.titulo;
    isExpanded = false;
    tema = 1;
    constructor(private usuariosService: UsuariosService, private translateService: TranslateService, private menuService: MenuService,private activatedRoute:ActivatedRoute) {
        this.usuariosService.user.subscribe(x => this.user = x);
        if (this.user != null) {
            translateService.setDefaultLang(this.user.idioma);
            this.isExpanded = this.user.menuExpandido;
            this.tema = this.user.tema;
            if (this.tema == 1) {
                const body = document.getElementsByTagName('body')[0];
                body.classList.remove('iluna');
            } else {
                const body = document.getElementsByTagName('body')[0];
                body.classList.add('iluna');
            }
        } else {
            translateService.setDefaultLang('eu');
            this.isExpanded = false;
        }
        //console.log('RUTA DESDE APPCOMPONENT');
        //console.log(activatedRoute.snapshot[0]?.url);
    }

   
    
      temaIluna() {
        this.tema = 2;
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido).subscribe();
        this.user.tema = this.tema;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
        body.classList.add('iluna');
      }
      temaArgia() {
        this.tema = 1;
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido).subscribe();
        this.user.tema = this.tema;
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
      }
      expandir() {
        this.isExpanded = !this.isExpanded;
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.user.tema, this.isExpanded).subscribe();
        this.user.menuExpandido = this.isExpanded;
      }

      useLanguage(language: string) {
        this.menuService.updateUserSettings(this.user.id, this.user.idDb, language, this.user.tema, this.user.menuExpandido).subscribe(
          f => (
            window.location.reload()
          )
        )
        let idIdioma:number=0;
        if(language=='es')
        idIdioma=1;
        else if(language=='eu')
        idIdioma=2;
        this.menuService.updateUserLang(idIdioma).subscribe((result)=>{
          console.log(result);
        });
      }

    logout() {
        this.usuariosService.logout();
    }

    
}


@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === 'saveUrl') {
      const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
        type: HttpEventType.UploadProgress,
        loaded: x,
        total: 100
      }).pipe(delay(1000)));

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
      events.push(success);

      return concat(...events);
    }

    if (req.url === 'removeUrl') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}