import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Idioma } from '@app/_models/idioma';
import {
  DataService,
  DropDownListComponent,
} from '@progress/kendo-angular-dropdowns';
import { AlertService } from '@app/_services';
import {
  Encuesta,
  EncuestaOpcion,
  EncuestaOpcionTraduccion,
  EncuestaTipo,
  EncuestaTraduccion,
} from '@app/_models/encuesta';
import { EncuestasService } from '@app/_services/encuestas.service';
import { NavigationEnd } from '@angular/router';
import { Usuario } from '@app/_models';
import { filter } from 'rxjs/operators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from "@progress/kendo-data-query";

@Component({
  selector: 'app-encuesta-resumen',
  templateUrl: './encuesta-resumen.component.html',
})
export class EncuestaResumenComponent implements OnInit {
  myEncuesta: Encuesta;
  listaOpciones: EncuestaOpcion[] = [];
  idOrg: string;
  idEncuesta: number;
  isBusy: boolean = false;
  votosTotales: number = 0;
  puntosTotales: number = 0;
  ganador: string;
  ganadorOBJ: EncuestaOpcion;
  empate: boolean = false;
  listaUsers: Usuario[] = [];

  userDNI: string = '';

  lang;
  private translate: TranslateService;

  public group: any[] = [
    {
      field: "dni",
    },
  ];


  constructor(
    private encuestaService: EncuestasService,
    private idiomaService: IdiomasService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('encuestas.resumen');
    this.allData = this.allData.bind(this);
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idEncuesta = this.route.snapshot.params['idencuesta'];

    this.encuestaService.getEmcuesta(this.idEncuesta).subscribe((result) => {
      console.log(result.data);
      this.myEncuesta = result.data[0];
      console.log('Encuesta');
      console.log(this.myEncuesta);
      //fechas
      this.myEncuesta.fecha_inicio = new Date(this.myEncuesta.fecha_inicio);
      this.myEncuesta.fecha_fin = new Date(this.myEncuesta.fecha_fin);

      //cargar opciones
      this.encuestaService
        .getOpcionesFromEncuesta(this.idEncuesta)
        .subscribe((result) => {
          console.log('OPCIONES');
          console.log(result);
          this.listaOpciones = result.data;

          let puntosMax = 0;
          this.listaOpciones.forEach((element) => {
            if (this.votosTotales <= element.votos)
              this.votosTotales = element.votos;
            this.puntosTotales = this.puntosTotales + element.puntuacion;
            if (puntosMax <= element.puntuacion) {
              this.ganador = element.opcion;
              this.ganadorOBJ = element;
              puntosMax = element.puntuacion;
            }
          });
          //Gestionar empate
          console.log('GANADOR');
          console.log(this.ganadorOBJ);
          this.listaOpciones.forEach((res) => {
            if (
              this.ganadorOBJ.puntuacion == res.puntuacion &&
              this.ganadorOBJ.id != res.id
            )
              this.empate = true;
          });

          //load users
          this.encuestaService
            .getUsuariosEncuestaVoted(this.idEncuesta)
            .subscribe((resultUsers) => {
              this.listaUsers = resultUsers.data;
              this.votosTotales = this.listaUsers.length;
            });
        });
    });
  }

  public labelContent(e: any): string {
    return e.dataItem.opcion;
  }

  //  imprimirXls(e, grid: GridComponent) {
  //    console.log('Exportando');
  //    e.preventDefault();
  //    setTimeout(function () {
  //    grid.saveAsExcel();
  //    });
  //    }

     public allData(): ExcelExportData {
      const result: ExcelExportData = {
        data: process(this.listaUsers, {
          sort: [{ field: "dni", dir: "asc" }],
        }).data,

      };
  
      return result;
    }

  btnBuscarUsuario() {
    if (this.userDNI.length < 9) {
      this.alertService.warn(
        this.translateService.instant('encuestas.dni_invalido')
      );
      return;
    }
    let listaTmp: Usuario[] = this.listaUsers.filter(
      (x) => x.nombreUsuario == this.userDNI
    );
    if (listaTmp.length > 0) {
      this.alertService.warn(
        this.translateService.instant('encuestas.usuariohavotado')
      );
    } else {
      this.alertService.success(
        this.translateService.instant('encuestas.usuarionohavotado')
      );
    }
  }
}
