import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';

import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { AplicacionesComponent } from './aplicaciones/aplicaciones.component';
import { AppCrearComponent } from './aplicaciones/app-crear/app-crear.component';
import { AppEditarComponent } from './aplicaciones/app-editar/app-editar.component';
import { PublicacionesComponent } from './publicaciones/publicaciones.component';
import { PublicacionCrearComponent } from './publicaciones/publicacion-crear/publicacion-crear.component';
import { PublicacionEditarComponent } from './publicaciones/publicacion-editar/publicacion-editar.component';
import { EventosComponent } from './eventos/eventos/eventos.component';
import { EventosCrearComponent } from './eventos/eventos-crear/eventos-crear.component';
import { EventosEditarComponent } from './eventos/eventos-editar/eventos-editar.component';
import { EncuestasComponent } from './encuestas/encuestas/encuestas.component';
import { EncuestasCrearComponent } from './encuestas/encuestas-crear/encuestas-crear.component';
import { EncuestasEditarComponent } from './encuestas/encuestas-editar/encuestas-editar.component';
import { RegisterPhoneSmsComponent } from './registersms/register-phone-sms/register-phone-sms.component';
import { EncuestaVotarComponent } from './encuestas/encuesta-votar/encuesta-votar.component';
import { EncuestaResumenComponent } from './encuestas/encuesta-resumen/encuesta-resumen.component';
import { ComprobarDniComponent } from './encuestas/comprobar-dni/comprobar-dni.component';

const loginModule = () =>
  import('./login/login.module').then((x) => x.LoginModule);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    loadChildren: loginModule,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: loginModule },
  {
    path: 'profile',
    component: DetailsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'update', component: UpdateComponent, canActivate: [AuthGuard] },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios-tipos',
    component: UsuariosTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  //My Routes
  {
    path: 'gestion-encuestas',
    component: EncuestasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-crear',
    component: EncuestasCrearComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-editar',
    component: EncuestasEditarComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-votar',
    component: EncuestaVotarComponent,
    canActivate: [AuthGuard],
    data: {  },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'encuestas-resumen',
    component: EncuestaResumenComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'phone-verification',
    component: RegisterPhoneSmsComponent,
  },
  {
    path: 'verificacion',
    component: ComprobarDniComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
