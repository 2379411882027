import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { delay, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, AlertService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { Usuario } from '@app/_models';
import { WindowService } from '@app/_services/window.service';

const config = {
  apiKey: 'AIzaSyBMv_F2mV-g8V4QFiLstnn0crxthEVFC1Y',
  authDomain: 'aioro-cd335.firebaseapp.com',
  projectId: 'aioro-cd335',
  storageBucket: 'aioro-cd335.appspot.com',
  messagingSenderId: '368650509931',
  appId: '1:368650509931:web:124693b9be462ce5511b2e',
  measurementId: 'G-SJF5BHHSLK',
};
import {
  Auth,
  getAuth,
  initializeAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';

import { initializeApp } from 'firebase/app';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { EncuestasService } from '@app/_services/encuestas.service';
@Component({
  selector: 'app-register-phone-sms',
  templateUrl: './register-phone-sms.component.html',
})
export class RegisterPhoneSmsComponent implements OnInit {
  windowRef: any;
  phone: string = '';
  nombre: string = '';
  username: string = '';
  dni: string = '';
  password1: string = '';
  password2: string = '';
  email: string = '';
  fechaNacimiento: Date;

  verificationCode: string = '';

  user: any;
  isBusy:boolean=false;
  verificado: boolean = false;

  recaptchaVerifier: RecaptchaVerifier;
  auth: Auth;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuariosService: UsuariosService,
    private encuestaService: EncuestasService,
    private alertService: AlertService,
    public translateService: TranslateService,
    private win: WindowService
  ) {
    translateService.setDefaultLang(translateService.getBrowserLang());
  }

  ngOnInit(): void {
    initializeApp(config);
    console.log('GetAuth ');
    this.auth = getAuth();
    this.auth.languageCode = 'es';
    console.log(this.auth);
    this.windowRef = this.win.windowRef;
  }

  sendLoginCode() {
    
    //Validaciones
    if (
      this.phone.length < 1 ||
      this.dni.length < 9 ||
      this.fechaNacimiento == null
    ) {
      this.alertService.info(this.translateService.instant('login.validacion'));
      return;
    }
    // if (this.password1 != this.password2) {
    //   this.alertService.info(
    //     this.translateService.instant('login.validacionpassword')
    //   );
    //   return;
    // }
    // if (this.password1.length < 5) {
    //   this.alertService.info(
    //     this.translateService.instant('login.passowrdlengthG6')
    //   );
    //   return;
    // }
    const anoActual = new Date().getFullYear();
    console.log('AÑO=');
    console.log(anoActual);
    if(anoActual - this.fechaNacimiento.getFullYear()<16){
      this.alertService.info(
        this.translateService.instant('registro.edadinsuficiente')
      );
      return;
    }

    this.isBusy=true;
    const year = this.fechaNacimiento.getFullYear();
    const month = this.addZero(this.fechaNacimiento.getMonth() + 1);
    const day = this.addZero(this.fechaNacimiento.getDate());
    const fecha = year.toString() + '' + month.toString() + '' + day.toString();

    const fechaNumber = Number(fecha);

    this.encuestaService
      .comprobar_dni(this.dni, fechaNumber)
      .subscribe((resultApi) => {
        console.log('API: ');
        console.log(resultApi);
        if (resultApi.estado == 'N') {
          this.alertService.warn(
            this.translateService.instant('registro.nopertenececenso')
          );
          this.isBusy=false;
          return;
        } else if (resultApi.estado == 'E') {
          this.alertService.warn(
            this.translateService.instant('registro.fechanacimientonodni')
          );
          this.isBusy=false;
          return;
        } else if (resultApi.estado == 'S') {
          console.log('Pertenece al censo!');
          //Envio SMS
          console.log('Iniciando recaptcha');
          this.windowRef.recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'invisible',
              callback: (response) => {
                console.log('recaptchaVerifier: ' + response);
              },
              catch: (error) => {
                console.log('ERROR recaptchaVerifier: ' + error);
              },
            },
            this.auth
          );
          console.log(this.windowRef.recaptchaVerifier);

          const appVerifier = this.windowRef.recaptchaVerifier;
          console.log(appVerifier);
          this.auth = getAuth();
          console.log('Enviando SMS...');
          signInWithPhoneNumber(this.auth, '+34'+this.phone, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              console.log('Respuesta obtenida:');
              console.log(confirmationResult);
              this.windowRef.confirmationResult = confirmationResult;
              this.isBusy=false;
              this.verificado = true;
              // ...
            })
            .catch((error) => {
              console.log(error);
              // Error; SMS not sent
              // ...
              console.log('ERROR: ' + error);
              this.isBusy=false;
              if(error.toString().includes('auth/too-many-requests')){
                this.alertService.info(
                  this.translateService.instant('registro.toomany'),
                  { keepAfterRouteChange: true }
                );
              }
            });
        }
      });
  }

  verifyLoginCode() {

this.isBusy=true;
    console.log(this.verificationCode);
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then((result) => {
        console.log(result);
        this.user = result.user;
        if (this.user) {
          this.registerUser();
          //this.router.navigate(['/']);
        }
      })
      .catch((error) => {
        this.isBusy=false;
        console.log(error);
        this.alertService.error(
          this.translateService.instant('registro.codigoinvalido'),
          { keepAfterRouteChange: true }
        );
      });
  }

  registerUser() {
    console.log('Registrando Usuario...');
    let myUser: Usuario = new Usuario();
    myUser.idDb = 0;
    myUser.nombreUsuario = this.dni;
    myUser.nombre = this.dni;
    myUser.apellido1 = '';
    myUser.apellido2 = '';
    myUser.descripcion = this.phone;
    myUser.email = 'villabona@zitu.net';
    myUser.Password = this.phone;
    myUser.ConfirmPassword = this.phone;
    this.usuariosService
      .register(myUser)
      .pipe(first())
      .subscribe({
        next: (res) => {
          this.isBusy=false;
          console.log('Respuesta register');
          console.log(res);
          this.alertService.success(
            this.translateService.instant('registro.success'),
            { keepAfterRouteChange: true }
          );
          //Autologin
          // this.router.navigate(['../login'], { relativeTo: this.route });
          this.usuariosService
          .login(0, myUser.nombreUsuario,  myUser.Password)
          .pipe(first())
          .subscribe({
            next: () => {
              //AQUI Redirigir a la encuesta especifica
              // get return url from query parameters or default to home page
              console.log('Login hecho! Redirigiendo');
              const returnUrl = '/encuestas-votar;idEncuesta=9';
              this.router.navigateByUrl(returnUrl);
            },
            error: (error) => {
              console.log('Error al loguear Authenticate ');
              this.alertService.success(
                this.translateService.instant('registro.otromovil'),
                { keepAfterRouteChange: true }
              );
            },
          });
        },
        error: (error) => {
          this.isBusy=false;
          console.log('Error al registrar usuario');
          this.alertService.error(error);
        },
      });
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }

  btnCancelar() {
    this.router.navigate(['home']);
  }
}
